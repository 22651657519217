import React from "react";

const AuthErrorAlert = ({ errors }) => {
  if (!errors || !errors.length) {
    return null;
  }

  return <div className="alert alert-danger text-secondary">{errors}</div>;
};

export default AuthErrorAlert;
