import React from "react";
import Skeleton from "react-loading-skeleton";

const BlogCardSkeleton = () => (
  <div className="card">
    <div className="d-block w-100 h-175px bg-light rounded-top" />
    <div className="card-inner p-3">
      <Skeleton width={82} height={14} />
      <Skeleton height={14} count={2} />
    </div>
  </div>
);

const BlogListLoading = () => {
  return (
    <div className="row py-4">
      {[0, 1, 2].map((id) => (
        <div key={id} className="col-sm-6 col-lg-4">
          <BlogCardSkeleton />
        </div>
      ))}
    </div>
  );
};

export default BlogListLoading;
