import {
  SIGN_IN,
  SIGN_OUT,
  AUTH_INIT,
  AUTH_ERROR,
  AUTH_ERROR_CLEAR,
  AUTH_SUBMIT,
  AUTH_SUBMITTED,
} from "../../types/authTypes";

const INITIAL_STATE = {
  user: null,
  isSubmitting: false,
  isAuthenticated: false,
  errors: [],
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_INIT:
      return { ...state, errors: [], isSubmitting: false };
    case AUTH_SUBMIT:
      return { ...state, isSubmitting: true };
    case AUTH_SUBMITTED:
      return { ...state, isSubmitting: false };
    case AUTH_ERROR:
      return { ...state, errors: action.payload };
    case AUTH_ERROR_CLEAR:
      return { ...state, errors: [] };
    case SIGN_IN:
      return { ...state, user: action.payload, isAuthenticated: true };
    case SIGN_OUT:
      return { ...state, user: null, isAuthenticated: false };
    default:
      return state;
  }
};

export default auth;
