import axios from "axios";
import _ from "lodash";

const BLOG_API_URL = process.env.REACT_APP_BLOG_API_URL;

class BlogApi {
  async posts() {
    let posts = [];

    try {
      const res = await axios.get(`${BLOG_API_URL}/posts`, {
        params: { per_page: 3 },
      });

      posts = await Promise.all(
        res.data.map(async (post) => {
          let _post = _.pick(post, [
            "id",
            "date",
            "title",
            "link",
            "featured_media",
          ]);
          _post.media_url = await this.media(post.featured_media);
          return _post;
        })
      );
    } catch {
      posts = [];
    }

    return posts;
  }

  async media(id) {
    let media = null;

    try {
      const res = await axios.get(`${BLOG_API_URL}/media/${id}`);
      media = res.data.source_url;
    } catch {
      media = null;
    }

    return media;
  }
}

export default BlogApi;
