import React from "react";
import { useField } from "formik";

const FormGroup = ({ htmlFor, label, children }) => {
  const [, meta] = useField(htmlFor);

  return (
    <div className="form-group">
      {label ? (
        <label className="form-label" htmlFor={htmlFor}>
          {label}
        </label>
      ) : null}
      <div className="form-control-wrap">
        {children}
        {meta.touched && meta.error ? (
          <span className="invalid">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

export default FormGroup;
