import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import withAuthentication from "../../hoc/withAuthentication";

import LoginGreeting from "./LoginGreeting";
import LoginForm from "../forms/LoginForm";
import AuthErrorAlert from "./AuthErrorAlert";

const Login = ({ signIn, isSubmitting, errors }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Fragment>
      <LoginGreeting />
      <AuthErrorAlert errors={errors} />
      <div className="text-left">
        <LoginForm
          onSubmit={(formValues) => signIn(formValues, history)}
          isSubmitting={isSubmitting}
        />
      </div>
      <Link className="text-secondary d-block mt-3" to="/auth/recovery">
        {t("auth.login.buttons.forgotPassword")}
      </Link>
    </Fragment>
  );
};

export default withAuthentication(Login);
