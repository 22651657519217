import React from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import FormGroup from "./FormGroup";
import { Input, SubmitButton } from "../inputs";

const PasswordRecoverySchema = (t) =>
  yup.object().shape({
    email: yup.string().email(t("yup.email")).required(t("yup.required")),
  });

const PasswordRecoveryInitValues = {
  email: "",
};

const PasswordRecoveryForm = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={PasswordRecoveryInitValues}
      validationSchema={PasswordRecoverySchema(t)}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ isValid, dirty }) => (
        <Form>
          <FormGroup label={t("forms.passwordRecovery.email")} htmlFor="email">
            <Input className="form-control-xl" type="email" name="email" />
          </FormGroup>
          <div className="form-group mt-5">
            <SubmitButton
              type="submit"
              className="btn-block"
              size="xl"
              disabled={!isValid || !dirty}
              submittingText={t("forms.passwordRecovery.submitting")}
              isSubmitting={isSubmitting}
            >
              {t("forms.passwordRecovery.submit")}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordRecoveryForm;
