import { APP_LOADED } from "../../types/appTypes";

const INITIAL_STATE = {
  isLoading: true,
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_LOADED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default app;
