import React from "react";
import classNames from "classnames";

const BlankLayout = ({ children, color }) => {
  const contentClass = classNames("nk-content", {
    [`bg-${color}`]: !!color,
  });

  return (
    <div className="nk-wrap nk-wrap-nosidebar">
      <div className={contentClass}>
        <div className="nk-block nk-block-middle wide-xs mx-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default BlankLayout;
