const SESSION_KEY = `${process.env.REACT_APP_STORAGE_KEY}/session`;
export const hasSession = () => localStorage.getItem(SESSION_KEY) !== null;
export const getSession = () => JSON.parse(localStorage.getItem(SESSION_KEY));

export const setSession = (session) => {
  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
};

export const clearSession = () => {
  localStorage.removeItem(SESSION_KEY);
};
