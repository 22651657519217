import { useEffect } from "react";

const SuccessContainer = ({ children, isSuccess, backIfIsNotSuccess }) => {
  useEffect(backIfIsNotSuccess);

  if (!isSuccess) {
    return null;
  }

  return children;
};

export default SuccessContainer;
