import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Icons from "./icons";

const Error = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const code = path.substring(1);
  const icon = Icons[`Error${code}`];

  return (
    <div className="nk-block-content nk-error-ld text-center">
      {icon ? (
        <img className="nk-error-gfx" src={icon} alt={`Error ${code}`} />
      ) : (
        <h1 className="nk-error-head">{code}</h1>
      )}
      <div className="wide-xs mx-auto">
        <h3 className="nk-error-title">{t(`errors.${code}.title`)}</h3>
        <p className="nk-error-text">{t(`errors.${code}.text`)}</p>
        <Link className="btn btn-lg btn-primary mt-2" to="/">
          {t(`errors.${code}.button`)}
        </Link>
      </div>
    </div>
  );
};

export default Error;
