import { useState, useEffect } from "react";

import BlogApi from "../api/blog";

const useBlog = () => {
  const [posts, setPosts] = useState([]);
  const [gettingPosts, setGettingPosts] = useState(true);

  useEffect(() => {
    async function getPosts() {
      try {
        const blog = new BlogApi();
        const _posts = await blog.posts();
        setPosts(_posts);
      } finally {
        setGettingPosts(false);
      }
    }

    getPosts();
  }, []);

  return {
    posts,
    gettingPosts,
  };
};

export default useBlog;
