import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PasswordRecoverySuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center pt-3">
      <em
        className="ni ni-check text-success border border-3 rounded-circle border-success p-2"
        style={{ fontSize: "42px", fontWeight: 700, border: "3px solid" }}
      />

      <div className="h6 pt-3 fw-normal mt-3 mb-6">
        {t("auth.passwordRecovery.success.message")}
        <br />
        {t("auth.passwordRecovery.success.linkExpires")}
      </div>
      <Link
        className="btn btn-outline-primary btn-block btn-lg"
        to="/auth/login"
      >
        <em
          className="ni ni-chevron-left mr-2"
          style={{
            fontSize: "18px",
          }}
        />
        {t("auth.passwordRecovery.success.link")}
      </Link>
    </div>
  );
};

export default PasswordRecoverySuccess;
