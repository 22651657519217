import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const BLOG_URL = process.env.REACT_APP_BLOG_URL;

const UrlIcon = styled.em`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #364a63;
  background-color: #fff;
  text-align: center;
  line-height: 32px;
  font-size: 28px;
  vertical-align: middle;
  padding-left: 2px;
`;

const BlogFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between align-items-center">
      <span className="text-gray fs-16px">{t("blog.tips")}</span>
      <a
        href={BLOG_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="text-uppercase text-white fw-bold fs-12px"
      >
        {t("blog.buttons.learnAbout")}
        <UrlIcon className="ni ni-chevron-right ml-4" />
      </a>
    </div>
  );
};

export default BlogFooter;
