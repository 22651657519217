import React from "react";

import logoDark from "./logo-dark.svg";

const Logo = () => {
  return (
    <div className="logo-link">
      <img className="logo-img logo-img-lg" src={logoDark} alt="logo" />
    </div>
  );
};

export default Logo;
