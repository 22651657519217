import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import AuthHeader from "./AuthHeader";

const greetings = {
  MORNING: "morning",
  AFTERNOON: "afternoon",
  EVENING: "evening",
};

const getGreeting = (hour) => {
  if (hour >= 6 && hour < 12) {
    return greetings.MORNING;
  } else if (hour >= 12 && hour < 18) {
    return greetings.AFTERNOON;
  }

  return greetings.EVENING;
};

const AuthGreeting = () => {
  const [greeting, setGreeting] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setGreeting(getGreeting(moment().hour()));
  }, [setGreeting]);

  return (
    <AuthHeader
      title={t("auth.greeting.text", {
        time: t(`auth.greeting.time.${greeting}`),
      })}
    >
      {t("auth.login.description")}
    </AuthHeader>
  );
};

export default AuthGreeting;
