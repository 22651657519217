import { useState, useCallback } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";

const useSuccessful = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const successCallback = useCallback(() => {
    setIsSuccess(true);
    history.push(`${pathname}/success`);
  }, [history, pathname]);

  const backIfIsNotSuccess = useCallback(() => {
    if (!isSuccess) {
      history.push(pathname.replace("/success", ""));
    }
  }, [history, isSuccess, pathname]);

  return {
    successCallback,
    backIfIsNotSuccess,
    path,
    isSuccess,
  };
};

export default useSuccessful;
