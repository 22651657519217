import _ from "lodash";
import {
  SIGN_IN,
  SIGN_OUT,
  AUTH_INIT,
  AUTH_ERROR,
  AUTH_ERROR_CLEAR,
  AUTH_SUBMIT,
  AUTH_SUBMITTED,
} from "../types/authTypes";

import { APP_LOADED } from "../types/appTypes";

import admin from "../api/admin";
import { setSession, clearSession } from "../utils/session";

export const authInit = () => (dispatch) => {
  clearSession();
  dispatch({ type: AUTH_INIT });
  dispatch({ type: APP_LOADED });
};

export const me = () => async (dispatch) => {
  try {
    const res = await admin.get("/me");
    dispatch({ type: SIGN_IN, payload: res.data });
    dispatch({ type: APP_LOADED });
  } catch (err) {
    console.log({
      err: err,
    });
  }
};

export const signIn = (formValues, history) => async (dispatch) => {
  dispatch({ type: AUTH_SUBMIT });

  try {
    const res = await admin.post("/authentication/sessions", {
      ...formValues,
    });
    setSession(_.pick(res.headers, ["uid", "client", "access-token"]));
    dispatch({ type: SIGN_IN, payload: res.data });
    history.push("/");
  } catch (err) {
    if (err.response && err.response.data && err.response.data.errors) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.errors });
    }
  } finally {
    dispatch({ type: AUTH_SUBMITTED });
  }
};

export const password = (formValues, cb, isSetNewPassword) => async (
  dispatch
) => {
  dispatch({ type: AUTH_SUBMIT });

  try {
    await admin({
      method: isSetNewPassword ? "PATCH" : "POST",
      url: "/authentication/passwords",
      data: { ...formValues },
    });
    dispatch({ type: AUTH_ERROR_CLEAR });
    if (cb) {
      cb();
    }
  } catch (err) {
    if (err.response && err.response.data && err.response.data.errors) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.errors });
    }
  } finally {
    dispatch({ type: AUTH_SUBMITTED });
  }
};

export const signOut = () => {
  clearSession();

  return {
    type: SIGN_OUT,
  };
};
