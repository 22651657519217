import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import withAuthentication from "../../../hoc/withAuthentication";
import useSuccessful from "../../../hooks/useSuccessful";

import PasswordRecoveryForm from "../../forms/PasswordRecoveryForm";
import AuthErrorAlert from "../AuthErrorAlert";
import AuthHeader from "../AuthHeader";
import PasswordRecoverySuccess from "./PasswordRecoverySuccess";
import SuccessfulRoute from "../../routes/SuccessfulRoute";

const PasswordRecovery = ({ password, isSubmitting, errors }) => {
  const { t } = useTranslation();
  const { successCallback, ...successful } = useSuccessful();

  return (
    <Fragment>
      <AuthHeader title={t("auth.passwordRecovery.title")}>
        {t("auth.passwordRecovery.description")}
      </AuthHeader>
      <AuthErrorAlert errors={errors} />
      <SuccessfulRoute
        successful={successful}
        SuccessComponent={PasswordRecoverySuccess}
      >
        <Fragment>
          <div className="text-left">
            <PasswordRecoveryForm
              onSubmit={(formValues) => password(formValues, successCallback)}
              isSubmitting={isSubmitting}
            />
          </div>
          <Link className="text-secondary d-block mt-3" to="/auth/login">
            {t("auth.passwordRecovery.buttons.login")}
          </Link>
        </Fragment>
      </SuccessfulRoute>
    </Fragment>
  );
};

export default withAuthentication(PasswordRecovery);
