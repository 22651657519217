import React from "react";

import BlogCard from "./BlogCard";

const BlogList = ({ posts }) => {
  return (
    <div className="row py-4">
      {posts.map((post) => (
        <div key={post.id} className="col-sm-6 col-lg-4">
          <BlogCard post={post} />
        </div>
      ))}
    </div>
  );
};

BlogList.defaultProps = {
  posts: [],
};

export default BlogList;
