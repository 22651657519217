import React from "react";

import Logo from "../app/Logo";

const AuthContainer = ({ children }) => {
  return (
    <div className="nk-block nk-block-middle nk-auth-body text-center">
      <div className="brand-logo mb-7">
        <Logo />
      </div>
      {children}
    </div>
  );
};

export default AuthContainer;
