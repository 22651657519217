import React from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import FormGroup from "./FormGroup";
import { Input, SubmitButton } from "../inputs";

const LoginSchema = (t) =>
  yup.object().shape({
    auth: yup.string().email(t("yup.email")).required(t("yup.required")),
    password: yup.string().required(t("yup.required")),
  });

const LoginInitValues = {
  auth: "",
  password: "",
};

const LoginForm = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={LoginInitValues}
      validationSchema={LoginSchema(t)}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ isValid, dirty }) => (
        <Form>
          <FormGroup label={t("forms.login.auth")} htmlFor="auth">
            <Input className="form-control-xl" type="email" name="auth" />
          </FormGroup>
          <FormGroup label={t("forms.login.password")} htmlFor="password">
            <Input
              className="form-control-xl"
              type="password"
              name="password"
            />
          </FormGroup>
          <div className="form-group mt-5">
            <SubmitButton
              type="submit"
              className="btn-block"
              size="xl"
              disabled={!isValid || !dirty}
              submittingText={t("forms.login.submitting")}
              isSubmitting={isSubmitting}
            >
              {t("forms.login.submit")}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
