import React from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import FormGroup from "./FormGroup";
import { Input, SubmitButton } from "../inputs";

const SetPasswordSchema = (t) =>
  yup.object().shape({
    password: yup.string().required(t("yup.required")),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password")], t("yup.passwords"))
      .required(t("yup.required")),
  });

const SetPasswordInitValues = {
  password: "",
  password_confirmation: "",
};

const SetPasswordForm = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={SetPasswordInitValues}
      validationSchema={SetPasswordSchema(t)}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ isValid, dirty }) => (
        <Form>
          <FormGroup label={t("forms.setPassword.password")} htmlFor="password">
            <Input
              className="form-control-xl"
              type="password"
              name="password"
            />
          </FormGroup>
          <FormGroup
            label={t("forms.setPassword.passwordConfirmation")}
            htmlFor="password_confirmation"
          >
            <Input
              className="form-control-xl"
              type="password"
              name="password_confirmation"
            />
          </FormGroup>
          <div className="form-group mt-5">
            <SubmitButton
              type="submit"
              className="btn-block"
              size="xl"
              disabled={!isValid || !dirty}
              submittingText={t("forms.setPassword.submitting")}
              isSubmitting={isSubmitting}
            >
              {t("forms.setPassword.submit")}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SetPasswordForm;
