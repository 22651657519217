import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AuthLayout, BlankLayout } from "../layouts";
import Error from "../app/Error";
import AuthRoutes from "./AuthRoutes";
import Admin from "../Admin";
import SetPassword from "../auth/SetPassword";

const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/auth">
          <AuthLayout>
            <AuthRoutes />
          </AuthLayout>
        </Route>
        <Route path="/password/:token">
          <BlankLayout color="secondary">
            <SetPassword />
          </BlankLayout>
        </Route>
        <Route path="/404" exact>
          <BlankLayout>
            <Error />
          </BlankLayout>
        </Route>
        <Route path="*">
          <Admin />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRoutes;
