import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

const BlogCard = ({ post }) => {
  const { t, i18n } = useTranslation();

  const postDate = useMemo(() => {
    const date = moment(post.date).locale(i18n.language);

    return {
      day: date.format("DD"),
      month: date.format("MMMM"),
    };
  }, [i18n.language, post]);

  return (
    <a
      href={post.link}
      className="card h-100"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={post.media_url}
        className="card-img-top"
        alt="post featured media"
      />
      <div className="card-inner p-3">
        <span className="text-primary d-block mb-1">
          {t("blog.date", { day: postDate.day, month: postDate.month })}
        </span>
        <h6>{post.title.rendered}</h6>
      </div>
    </a>
  );
};

export default BlogCard;
