import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import Login from "../auth/Login";
import PasswordRecovery from "../auth/PasswordRecovery";

const AuthRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/login`} exact>
        <Login />
      </Route>
      <Route path={`${path}/recovery`}>
        <PasswordRecovery />
      </Route>
      <Route path="*">
        <Redirect to="/404" />
      </Route>
    </Switch>
  );
};

export default AuthRoutes;
