import React, { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const SubmitButton = ({
  children,
  submittingText,
  disabled,
  isSubmitting,
  color,
  size,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const buttonClass = classNames("btn", className, {
    [`btn-${size}`]: true,
    [`btn-${color}`]: true,
  });

  const defaultText = children || t("forms.buttons.submit");
  const whenSubmittingText = submittingText || t("forms.buttons.submitting");

  return (
    <button
      className={buttonClass}
      disabled={disabled || isSubmitting}
      {...props}
    >
      {isSubmitting ? (
        <Fragment>
          <div className="spinner-border spinner-border-sm mr-3" />
          <span>{whenSubmittingText}</span>
        </Fragment>
      ) : (
        defaultText
      )}
    </button>
  );
};

SubmitButton.defaultProps = {
  color: "primary",
  size: "lg",
};

export default SubmitButton;
