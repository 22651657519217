import React from "react";
import { useField } from "formik";
import classNames from "classnames";

const Input = ({ className, ...props }) => {
  const [field, meta] = useField(props);

  const inputClass = classNames("form-control", className, {
    error: meta.touched && meta.error,
  });

  return <input className={inputClass} id={props.name} {...field} {...props} />;
};

export default Input;
