import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SetPasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <em
        className="ni ni-check text-success border border-3 rounded-circle border-success p-2"
        style={{ fontSize: "42px", fontWeight: 700, border: "3px solid" }}
      />

      <div className="h6 pt-3 fw-normal mt-3 mb-7">
        {t("auth.setPassword.success.message")}
      </div>
      <Link
        className="btn btn-outline-primary btn-block btn-xl"
        to="/auth/login"
      >
        {t("auth.setPassword.success.link")}
      </Link>
    </div>
  );
};

export default SetPasswordSuccess;
