import React, { useEffect } from "react";
import { connect } from "react-redux";
import { authInit, signIn, password } from "../actions/authActions";

const withAuthentication = (Component) => {
  const AuthComponent = ({ authInit, ...props }) => {
    useEffect(() => {
      authInit();
    }, [authInit]);

    return <Component {...props} />;
  };

  const mapStateToProps = (state) => ({
    isSubmitting: state.auth.isSubmitting,
    errors: state.auth.errors,
  });

  return connect(mapStateToProps, { signIn, password, authInit })(
    AuthComponent
  );
};

export default withAuthentication;
