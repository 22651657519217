import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import SuccessContainer from "./SuccessContainer";

const SuccessfulRoute = ({ children, SuccessComponent, successful }) => {
  const { backIfIsNotSuccess, path, isSuccess } = successful;
  return (
    <Switch>
      <Route path={path} exact>
        {children}
      </Route>
      <Route path={`${path}/success`}>
        <SuccessContainer
          isSuccess={isSuccess}
          backIfIsNotSuccess={backIfIsNotSuccess}
        >
          <SuccessComponent />
        </SuccessContainer>
      </Route>
      <Route path="*">
        <Redirect to="/404" />
      </Route>
    </Switch>
  );
};

export default SuccessfulRoute;
