import React from "react";
import Logo from "./Logo";

const LoadingApp = () => {
  return (
    <div className="nk-block-content text-center">
      <div>
        <Logo />
      </div>
      <div className="spinner-border mt-2">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingApp;
