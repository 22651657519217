import React from "react";

import AuthContainer from "../auth/AuthContainer";
import Blog from "../app/Blog";

const AuthLayout = ({ children }) => {
  return (
    <div className="nk-wrap nk-wrap-nosidebar">
      <div className="nk-content">
        <div className="nk-split nk-split-page nk-split-md">
          <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
            <AuthContainer>{children}</AuthContainer>
          </div>

          <div className="nk-split-content nk-split-stretch bg-secondary d-flex toggle-break-lg toggle-slide toggle-slide-right">
            <Blog />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
