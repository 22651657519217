import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { hasSession } from "../utils/session";
import { me } from "../actions/authActions";
import { loaded } from "../actions/appActions";

import LoadingApp from "../components/app/LoadingApp";
import { BlankLayout } from "../components/layouts";

const withAuthenticated = (Component) => {
  const AuthenticatedComponent = ({
    isAuthenticated,
    isLoading,
    me,
    loaded,
  }) => {
    const history = useHistory();

    useEffect(() => {
      if (!hasSession()) {
        history.push("/auth/login");
        loaded();
        return false;
      }

      if (!isAuthenticated) {
        me();
      }
    }, [isAuthenticated, history, loaded, me]);

    if (isLoading) {
      return (
        <BlankLayout>
          <LoadingApp />
        </BlankLayout>
      );
    }

    return <Component />;
  };

  const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.app.isLoading,
  });

  return connect(mapStateToProps, { me, loaded })(AuthenticatedComponent);
};

export default withAuthenticated;
