import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import withAuthentication from "../../../hoc/withAuthentication";
import useSuccessful from "../../../hooks/useSuccessful";

import SetPasswordForm from "../../forms/SetPasswordForm";
import AuthErrorAlert from "../AuthErrorAlert";
import AuthHeader from "../AuthHeader";
import SetPasswordSuccess from "./SetPasswordSuccess";
import SuccessfulRoute from "../../routes/SuccessfulRoute";
import Logo from "../../app/Logo";

const RoundedWrapper = styled.div`
  border-radius: 1rem;
`;

const PasswordRecovery = ({ password, isSubmitting, errors }) => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { successCallback, ...successful } = useSuccessful();

  return (
    <RoundedWrapper className="card bg-lighter mx-4">
      <div className="card-inner p-6 text-center">
        <div className="brand-logo mb-6">
          <Logo />
        </div>
        <AuthHeader title={t("auth.setPassword.title")}>
          {t("auth.setPassword.description")}
        </AuthHeader>
        <AuthErrorAlert errors={errors} />
        <SuccessfulRoute
          successful={successful}
          SuccessComponent={SetPasswordSuccess}
        >
          <Fragment>
            <div className="text-left">
              <SetPasswordForm
                onSubmit={(formValues) =>
                  password(
                    { ...formValues, reset_password_token: token },
                    successCallback,
                    true
                  )
                }
                isSubmitting={isSubmitting}
              />
            </div>
            <Link className="text-secondary d-block mt-3" to="/auth/login">
              {t("auth.setPassword.buttons.login")}
            </Link>
          </Fragment>
        </SuccessfulRoute>
      </div>
    </RoundedWrapper>
  );
};

export default withAuthentication(PasswordRecovery);
