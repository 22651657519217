import axios from "axios";
import { getSession } from "../utils/session";
import store from "../stores";
import { signOut } from "../actions/authActions";

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use((config) => {
  const session = getSession();
  if (session) {
    config.headers = session;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && getSession()) {
      store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);

export default api;
