import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "@hospedin/dashlite-theme";

import "./utils/i18n";
import "moment/locale/pt-br";

import store from "./stores";
import App from "./components/App";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
