import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useBlog } from "../../../hooks";
import BlogList from "./BlogList";
import BlogListLoading from "./BlogListLoading";
import BlogFooter from "./BlogFooter";

const BlogContainer = styled.div`
  max-width: 800px;
`;

const Blog = () => {
  const { t } = useTranslation();
  const { posts, gettingPosts } = useBlog();

  return (
    <BlogContainer className="w-100 p-3 p-sm-5 m-auto">
      <h4 className="text-white mb-0">{t("blog.title")}</h4>
      {gettingPosts ? <BlogListLoading /> : <BlogList posts={posts} />}
      <BlogFooter />
    </BlogContainer>
  );
};

export default Blog;
