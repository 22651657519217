import React from "react";

const AuthHeader = ({ title, children, tag }) => {
  const Tag = tag || "h4";
  return (
    <div className="nk-block-head pb-0 mb-7">
      <div className="nk-block-head-content">
        <Tag className="nk-block-title mb-3">{title}</Tag>
        <div className="h6 fw-normal">
          <p>{children}</p>
        </div>
      </div>
    </div>
  );
};

export default AuthHeader;
